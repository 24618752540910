import { Operations } from "../../api";

let initialState = {
  OperationsHistoryList: [],
  OperationsHistoryLoading: false,
  operationsTypes: [],
  history: [],
  total: [],
};

const OperationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_OPERATIONS_LIST": {
      return {
        ...state,
        OperationsHistoryList: action.data,
        OperationsHistoryLoading: true,
      };
    }

    case "SET_HISTORY": {
      return {
        ...state,
        history: Object.values(
          typeof action?.data?.operations === "object" &&
            action?.data?.operations
        ).filter((item) => item.type === "Покупка" || item.type === "Продажа"),
        total: action.data?.total,
      };
    }

    case "SET_OPERATIONS_TYPES": {
      return {
        ...state,
        operationsTypes: action.data,
        OperationsHistoryLoading: true,
      };
    }

    default:
      return state;
  }
};
export const setOperationsHistory = (data) => ({
  type: "SET_OPERATIONS_LIST",
  data,
});

export const setHistory = (data) => ({
  type: "SET_HISTORY",
  data,
});

export const setOperationsTypes = (data) => ({
  type: "SET_OPERATIONS_TYPES",
  data,
});

export const getOperationList = (data) => {
  return (dispath) => {
    Operations.getOperationsHistory(data).then((response) =>
      dispath(setOperationsHistory(response))
    );
  };
};

export const getHistory = (data) => (dispath) => {
  Operations.getOperationsHistory(data).then((response) =>
    dispath(setHistory(response))
  );
};

export const getOperationsTypes = () => {
  return (dispath) => {
    Operations.getOperationsTypes().then((response) =>
      dispath(setOperationsTypes(response))
    );
  };
};

export default OperationsReducer;
