import { Register } from "../../api"
let initialState = {
    answerRegisterAlert: {
        open: false,
        message: "",
        severity: ''
    },
}
const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ANSWER_REGISTER': {
            return {
                ...state,
                answerRegisterAlert: action.alert
            }
        }
        default: return state
    }
}
export const setAnswerRegister = (alert) => ({ type: 'ANSWER_REGISTER', alert })

export const postRegisterData = (register) => {
    return (dispath) => {
        Register.postRegister(register)
            .then(response => dispath(setAnswerRegister({ open: true, message: response.data, severity: response.status })))
    }
}
export default registerReducer
