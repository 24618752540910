import { Profile } from "../../api";
let initialState = {
  allBalances: [],
  profileData: [],
  profileBalance: [],
  profileActionLog: [],
  securityParameters: [],
  changedParams: [],
  isReloadProfileData: false,
  answerMyProfileAlert: {
    open: false,
    message: "",
    severity: "",
  },
  verificationAnswer: {
    open: false,
    message: "",
    severity: "",
  },
  VerificationDataList: [],
};

const MyProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BALANCES": {
      return {
        ...state,
        allBalances: Object.values(
          typeof action?.data?.currencies === "object" &&
            action?.data?.currencies
        ).sort((a, b) => b.balance - a.balance),
      };
    }

    case "PROFILE_DATA": {
      return {
        ...state,
        profileData: action.data,
        isReloadProfileData: true,
      };
    }
    case "MY_PROFILE_ANSWER": {
      return {
        ...state,
        answerMyProfileAlert: action.alert,
        isReloadProfileData: true,
      };
    }

    case "PROFILE_BALANCE": {
      return {
        ...state,
        profileBalance: action.data,
        isReloadProfileData: true,
      };
    }

    case "PROFILE_ACTIONLOG": {
      return {
        ...state,
        profileActionLog: action.data,
        isReloadProfileData: true,
      };
    }
    case "SET_EDIT_PROFILE_ANSWER": {
      return {
        ...state,
        answerMyProfileAlert: action.alert,
      };
    }
    case "SET_VERIFICATION": {
      return {
        ...state,
        verificationAnswer: action.alert,
      };
    }
    case "SET_VERIFICATION_DATA_LIST": {
      return {
        ...state,
        VerificationDataList: action.alert,
      };
    }
    case "GET_SECURITY_PARAMERS": {
      return {
        ...state,
        securityParameters: action.data,
      };
    }

    case "SET_SECURITY_PARAMERS": {
      return {
        ...state,
        changedParams: action.data,
      };
    }

    default:
      return state;
  }
};

export const setEditProfile = (alert) => ({
  type: "SET_EDIT_PROFILE_ANSWER",
  alert,
});

export const setSecurityParameters = (data) => ({
  type: "GET_SECURITY_PARAMERS",
  data,
});

export const setChangedSecurityParameters = (data) => ({
  type: "SET_SECURITY_PARAMERS",
  data,
});

export const editProfile = (data) => {
  return (dispacth) => {
    Profile.editProfile(data).then((response) =>
      dispacth(
        setMyProfile({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const setVerification = (alert) => ({ type: "SET_VERIFICATION", alert });
export const setBalances = (data) => ({ type: "SET_BALANCES", data });

export const setMyProfile = (alert) => ({ type: "MY_PROFILE_ANSWER", alert });
export const setProfileBalance = (data) => ({
  type: "PROFILE_BALANCE",
  data,
});
export const setProfileActionLog = (data) => ({
  type: "PROFILE_ACTIONLOG",
  data,
});
export const answerProfileData = (data) => ({ type: "PROFILE_DATA", data });

export const getBalances = () => (dispacth) =>
  Profile.getBalances().then((response) => dispacth(setBalances(response)));

export const postProfilePhoto = (avatar) => {
  return (dispacth) => {
    Profile.postImgAvatar(avatar).then((response) =>
      dispacth(
        setMyProfile({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const getProfileData = () => {
  return (dispacth) => {
    Profile.getProfile().then((response) =>
      dispacth(answerProfileData(response))
    );
  };
};

export const getProfileBalance = () => {
  return (dispacth) => {
    Profile.getProfileBalance().then((response) =>
      dispacth(setProfileBalance(response))
    );
  };
};
export const getProfileActionLog = (page) => {
  return (dispacth) => {
    Profile.getProfileActionLog(page).then((response) =>
      dispacth(setProfileActionLog(response))
    );
  };
};

export const getSecurityParametrs = () => {
  return (dispacth) => {
    Profile.getSecurityParametrs().then((response) =>
      dispacth(setSecurityParameters(response))
    );
  };
};

export const editSecurityParameters = (data) => {
  return (dispacth) => {
    Profile.editSecurityParameters(data).then((response) =>
      dispacth(
        setChangedSecurityParameters({
          open: true,
          message: response.data,
          severity: response.status,
          data: response,
        })
      )
    );
  };
};

export const postChangePassword = (newPassword) => {
  return (dispacth) => {
    Profile.changePassword(newPassword).then((response) =>
      dispacth(
        setMyProfile({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const removeImgAvatar = () => {
  return (dispacth) => {
    Profile.removeImgAvatar().then((response) =>
      dispacth(
        setMyProfile({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const verificationProfilePersonal = (data) => {
  return (dispacth) => {
    Profile.verificationProfile(data).then((response) =>
      dispacth(
        setVerification({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};
export default MyProfileReducer;
