import { Sell } from "../../api";

let initialState = {
  setSellByParameterCoin: [],
  sell: {
    open: false,
    message: "",
    severity: "",
  },
  sell_confirmation: {
    open: false,
    message: "",
    severity: "",
  },
};

const sell = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELL_PARAMETER": {
      return {
        ...state,
        setSellByParameterCoin: action.data,
      };
    }
    case "SET_SELL_DATA": {
      return {
        ...state,
        sell: action.data,
      };
    }
    case "SET_SELL_CONFIRMATION_DATA": {
      return {
        ...state,
        sell_confirmation: action.data,
      };
    }
    default:
      return state;
  }
};
export const setSellByParameter = (data) => ({
  type: "SET_SELL_PARAMETER",
  data,
});
export const setSellData = (data) => ({ type: "SET_SELL_DATA", data });
export const setSellConfirmationData = (data) => ({
  type: "SET_SELL_CONFIRMATION_DATA",
  data,
});

export const getSellByParametersCoin = (parameter) => {
  return (dispath) => {
    Sell.getSellByParameters(parameter).then((response) =>
      dispath(setSellByParameter(response))
    );
  };
};

export const makeSell = (data) => {
  return (dispath) => {
    Sell.makeSell(data).then((response) =>
      dispath(
        setSellData({
          open: response.status === 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const confirmSell = (id) => {
  return (dispath) => {
    Sell.confirmSell(id).then((response) =>
      dispath(
        setSellConfirmationData({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};
export default sell;
