import { BinancePay } from "../../api";

let initialState = {
  qrCode: "",
  payment_details: { error_code_from_pay24: "0" },
};

const binancePay = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QR_CODE": {
      return {
        ...state,
        qrCode: action.data,
      };
    }

    case "SET_PAYMENT_DETAILS": {
      return {
        ...state,
        payment_details: action.data,
      };
    }

    default:
      return state;
  }
};

export const setQrCode = (data) => ({
  type: "SET_QR_CODE",
  data,
});

export const setPaymentDetails = (data) => ({
  type: "SET_PAYMENT_DETAILS",
  data,
});

export const getQrCode = (id) => (dispatch) =>
  BinancePay.getQrCode(id).then((response) => dispatch(setQrCode(response)));

export const getPaymentDetails = (id) => (dispatch) =>
  BinancePay.getPaymentDetails(id).then((response) =>
    dispatch(setPaymentDetails(response))
  );

export default binancePay;
