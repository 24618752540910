import { Countries } from "../../api";

let initialState = {
    CountriesList: [],
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COUNTRIES": {
      return {
        ...state,
        CountriesList: action.data,
      };
    }
    default:
      return state;
  }
};

export const setCountries = (data) => ({ type: "GET_COUNTRIES", data });

export const getCountriesList = () => {
  return (dispath) => {
    Countries.getCountries().then((response) => dispath(setCountries(response)));
  };
};

export default countriesReducer;
