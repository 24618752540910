import { combineReducers, createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import infoReducer from "./reducers/infoReducer";
import questionReducer from "./reducers/questionsReducer";
import registerReducer from "./reducers/register";
import loginReducer from "./reducers/login";
import forgotReducer from "./reducers/forgot";
import MyProfileReducer from "./reducers/myProfile";
import replenishmentReducer from "./reducers/replenishment";
import ActivationAccountReducer from "./reducers/activation";
import MainReducer from "./reducers/Main";
import СurrenciesReducer from "./reducers/Сurrencies";
import OperationsReducer from "./reducers/Operations";
import SellReducer from "./reducers/sell";
import countriesReducer from "./reducers/countries";
import BayReducer from "./reducers/buy";
import withdrawReducer from "./reducers/withdraw";
import BuyReducer from "./reducers/buy";
import buy from "./reducers/buy1";
import sell from "./reducers/sell1";
import internalReducer from "./reducers/internal";
import P2PReducer from "./reducers/P2P";
import reviewsReducer from "./reducers/reviews";
import Pay24Reducer from "./reducers/pay24";
import f2aReducer from "./reducers/2fa";
import orders from "./reducers/orders";
import binancePay from "./reducers/binancePay";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  infoReducer,
  questionReducer,
  registerReducer,
  loginReducer,
  forgotReducer,
  MyProfileReducer,
  replenishmentReducer,
  ActivationAccountReducer,
  MainReducer,
  СurrenciesReducer,
  OperationsReducer,
  SellReducer,
  countriesReducer,
  BayReducer,
  withdrawReducer,
  BuyReducer,
  buy,
  sell,
  internalReducer,
  P2PReducer,
  reviewsReducer,
  Pay24Reducer,
  f2aReducer,
  orders,
  binancePay,
});

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
