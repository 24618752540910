import { News } from "../../api";

let initialState = {
  news: [],
  newsList: []
};

const infoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NEWS": {
      return {
        ...state,
        newsList: action.data,
      };
    }
    case "GET_NEWS_BY_ID": {
      return {
        ...state,
        news: action.data,
      };
    }

    default:
      return state;
  }
};

export const setNews = (data) => ({ type: "GET_NEWS", data });
export const setNewsById = (data) => ({ type: "GET_NEWS_BY_ID", data });

export const getNews = () => {
  return (dispath) => {
    News.getNews().then((response) => dispath(setNews(response)));
  };
};
export const getNewsById = (id) => {
  return (dispath) => {
    News.getNewsById(id).then((response) => dispath(setNewsById(response)));
  };
};

export default infoReducer;
