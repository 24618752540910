import { f2a } from "../../api";

let initialState = {
  generatedCode: [],
  isLoading: true,
  response: {
    open: false,
    message: "",
    severity: "",
  },
};

const f2aReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GENERATED_CODE": {
      return {
        ...state,
        generatedCode: action.data,
        isLoading: false,
      };
    }

    case "SET_DATA": {
      return {
        ...state,
        response: action.data,
      };
    }

    default:
      return state;
  }
};

export const setCode = (data) => ({
  type: "SET_GENERATED_CODE",
  data,
});

export const setData = (data) => ({
  type: "SET_DATA",
  data,
});

export const generateCode = () => {
  return (dispatch) => {
    f2a.generateCode().then((response) => dispatch(setCode(response)));
  };
};

export const enable2fa = (data) => {
  return (dispatch) => {
    f2a.enable2fa(data).then((response) =>
      dispatch(
        setData({
          open: true,
          message: response.data,
          severity: response.status,
          data: response,
        })
      )
    );
  };
};

export const disable2fa = (data) => {
  return (dispatch) => {
    f2a.disable2fa(data).then((response) =>
      dispatch(
        setData({
          open: true,
          message: response.data,
          severity: response.status,
          data: response,
        })
      )
    );
  };
};

export default f2aReducer;
