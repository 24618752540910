import { Buy } from "../../api";

let initialState = {
  BuyByParameterCoin: [],
  buy: {
    open: false,
    message: null,
    severity: null,
  },
  buy_confirmation: {
    open: false,
    message: null,
    severity: null,
  },
};

const buy = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BAY_PARAMETER": {
      return {
        ...state,
        BuyByParameterCoin: action.data,
      };
    }
    case "SET_BUY_DATA": {
      return {
        ...state,
        buy: action.data,
      };
    }
    case "SET_BUY_CONFIRMATION_DATA": {
      return {
        ...state,
        buy_confirmation: action.data,
      };
    }
    default:
      return state;
  }
};

export const setBuyByParameter = (data) => ({
  type: "SET_BAY_PARAMETER",
  data,
});

export const setBuyData = (data) => ({ type: "SET_BUY_DATA", data });

export const setBuyConfirmationData = (data) => ({
  type: "SET_BUY_CONFIRMATION_DATA",
  data,
});

export const getBuyByParametersCoin = (parameter) => {
  return (dispath) => {
    Buy.getBuyByParameters(parameter).then((response) =>
      dispath(setBuyByParameter(response))
    );
  };
};

export const makeBuy = (data) => {
  return (dispath) => {
    Buy.makeBuy(data).then((response) =>
      dispath(
        setBuyData({
          open: response.status === 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const confirmBuy = (id) => {
  return (dispath) => {
    Buy.confirmBuy(id).then((response) =>
      dispath(
        setBuyConfirmationData({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export default buy;
