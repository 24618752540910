import { Forgot } from "../../api"
let initialState = {
    answerForgotAlert: {
        open: false,
        message: "",
        severity: ''
    },
}
const forgotReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ANSWER_FORGOT': {
            return {
                ...state,
                answerForgotAlert: action.alert
            }
        }
        default: return state
    }
}
export const setAnswerForgot= (alert) => ({ type: 'ANSWER_FORGOT', alert})
export const postForgotLogin = (forgotData) => {
    return (dispath) => {
         Forgot.postforgot(forgotData)
        .then(response => dispath(setAnswerForgot({ open: true, message: response.data, severity: response.status})))
     }
}
export default forgotReducer
