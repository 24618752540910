import { Redirect, Route } from "react-router"
import { isAuthenticated } from "./isAuthenticated"

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/accounts/login",
					}}
					
				/>
			)
		}
	/>
)
