import { Login } from "../../api";
let initialState = {
  answerLoginAlert: {
    open: false,
    message: "",
    severity: "",
  },
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ANSWER_LOGIN": {
      return {
        ...state,
        answerLoginAlert: action.alert,
      };
    }
    default:
      return state;
  }
};
export const setAnswerLogin = (alert) => ({ type: "ANSWER_LOGIN", alert });
export const postLoginData = (login) => {
  return (dispath) => {
    Login.postLogin(login).then((response) =>
      dispath(
        setAnswerLogin({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};
export default loginReducer;
