import { Buy } from "../../api";

let initialState = {
  BuyByParameterCoin: [],
  answerCreateBuy: {
    open: false,
    message: "",
    severity: "",
  },
  answerBuyConfirm: {
    open: false,
    message: "",
    severity: "",
  },
};

const buy = (state = initialState, action) => {
  switch (action.type) {
    case "SET_BAY_PARAMETER": {
      return {
        ...state,
        BuyByParameterCoin: action.data,
      };
    }
    case "SET_BAY_CREATE": {
      return {
        ...state,
        answerCreateBuy: action.alert,
      };
    }
    case "SET_BAY_CONFIRM": {
      return {
        ...state,
        answerBuyConfirm: action.alert,
      };
    }
    default:
      return state;
  }
};
export const setBuyByParameter = (data) => ({
  type: "SET_BAY_PARAMETER",
  data,
});
export const setBuyCreate = (alert) => ({ type: "SET_BAY_CREATE", alert });
export const setBuyConfirm = (alert) => ({ type: "SET_BAY_CONFIRM", alert });

export const getBuyByParametersCoin = (parameter) => {
  return (dispath) => {
    Buy.getBuyByParameters(parameter).then((response) =>
      dispath(setBuyByParameter(response))
    );
  };
};

export const CreateBuy = (data) => {
  return (dispath) => {
    Buy.makeSell(data).then((response) =>
      dispath(
        setBuyCreate({
          open: response.status === 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const getBuyConfirm = (id) => {
  return (dispath) => {
    Buy.confirmSell(id).then((response) =>
      dispath(
        setBuyConfirm({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};
export default buy;
