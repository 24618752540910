import { Internal } from "../../api/index";

const initialState = {
  createInternalData: [],
  acceptedInternal: [],
  internalList: [],
  internalQuantity: [],
  internalCommission: [],
  isAlertOpen: false,
  isLoading: true,
};

const internalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_INTERNAL": {
      return {
        ...state,
        createInternalData: action.data,
      };
    }

    case "GET_INTERNAL_LIST": {
      return {
        ...state,
        internalList: action.data,
        isLoading: false,
      };
    }

    case "ACCEPT_INTERNAL": {
      return {
        ...state,
        acceptedInternal: action.data,
      };
    }

    case "OPEN_ALERT": {
      return {
        ...state,
        isAlertOpen: action.data,
      };
    }

    case "GET_INTERNAL_QUANTITY": {
      return {
        ...state,
        internalQuantity: action.data,
      };
    }

    case "GET_INTERNAL_COMMISSION": {
      return {
        ...state,
        internalCommission: action.data,
      };
    }

    default:
      return state;
  }
};

export const setCreatedInternal = (data) => ({ type: "CREATE_INTERNAL", data });
export const setInternalList = (data) => ({ type: "GET_INTERNAL_LIST", data });
export const setInternalCommission = (data) => ({
  type: "GET_INTERNAL_COMMISSION",
  data,
});

export const setInternalQuantity = (data) => ({
  type: "GET_INTERNAL_QUANTITY",
  data,
});
export const setAcceptedTransferData = (data) => ({
  type: "ACCEPT_INTERNAL",
  data,
});
export const setAlert = (data) => ({ type: "OPEN_ALERT", data });

export const createInternal = (data) => {
  return (dispath) => {
    Internal.createInternal(data).then((response) =>
      dispath(setCreatedInternal(response))
    );
  };
};

export const getInternalList = () => {
  return (dispath) => {
    Internal.getInternalList().then((response) =>
      dispath(setInternalList(response))
    );
  };
};

export const acceptInternal = (id, code) => {
  return (dispath) => {
    Internal.acceptInternal(id, code).then((response) =>
      dispath(setAcceptedTransferData(response))
    );
  };
};

export const getInternalQuantity = () => {
  return (dispath) => {
    Internal.getInternalQuantity().then((response) =>
      dispath(setInternalQuantity(response))
    );
  };
};

export const getInternalCommission = () => {
  return (dispath) => {
    Internal.getInternalCommission().then((response) =>
      dispath(setInternalCommission(response))
    );
  };
};

export default internalReducer;
