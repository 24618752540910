import { Pay24 } from "../../api";

let initialState = {
  Categories: [],
  Services: [],
  name: "",
  ServicesDetail: [],
  check: [],
  payment: [],
  isDetailLoading: true,
  isLoadingServices: true,
  answerCreateBuy: {
    open: false,
    message: "",
    severity: "",
  },
};

const Pay24Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CATEGORY": {
      return {
        ...state,
        Categories: action.data,
      };
    }

    case "SET_NAME": {
      return {
        ...state,
        name: action.data,
      };
    }

    case "SET_BY_ID_SERVICE": {
      return {
        ...state,
        Services: action.data,
        isLoadingServices: false,
      };
    }
    case "SET_BY_ID_SERVICE_DETAIL": {
      return {
        ...state,
        ServicesDetail: action.data,
        isDetailLoading: false,
      };
    }
    case "SET_BAY_CREATE": {
      return {
        ...state,
        answerCreateBuy: action.alert,
      };
    }

    case "SET_LOADING": {
      return {
        ...state,
        isLoadingServices: action.data,
      };
    }

    case "SET_DETAIL_LOADING": {
      return {
        ...state,
        isDetailLoading: action.data,
      };
    }

    case "SET_PAYMENT_DATA": {
      return {
        ...state,
        payment: action.data,
      };
    }

    case "SET_PAYMENT_CHECK": {
      return {
        ...state,
        check: action.data,
      };
    }

    default:
      return state;
  }
};
export const setCategory = (data) => ({ type: "SET_CATEGORY", data });
export const setName = (data) => ({ type: "SET_NAME", data });
export const setLoading = (data) => ({ type: "SET_LOADING", data });
export const setIsDetailLoading = (data) => ({
  type: "SET_DETAIL_LOADING",
  data,
});
export const setByIdService = (data) => ({ type: "SET_BY_ID_SERVICE", data });
export const setPaymentData = (data) => ({ type: "SET_PAYMENT_DATA", data });
export const setPaymentCheck = (data) => ({ type: "SET_PAYMENT_CHECK", data });
export const setByIdServiceDetail = (data) => ({
  type: "SET_BY_ID_SERVICE_DETAIL",
  data,
});

export const setBuyCreate = (alert) => ({ type: "SET_BAY_CREATE", alert });

export const getCategories = () => {
  return (dispath) => {
    Pay24.getCategories().then((response) => dispath(setCategory(response)));
  };
};

export const getByIdService = (id) => {
  return (dispath) => {
    Pay24.getByIdService(id).then((response) =>
      dispath(setByIdService(response))
    );
  };
};
export const getByIdServiceDetail = (id) => {
  return (dispath) => {
    Pay24.getByIdServiceDetail(id).then((response) =>
      dispath(setByIdServiceDetail(response))
    );
  };
};

export const CreateBuy = (data) => {
  return (dispath) => {
    Pay24.BuyCreate(data).then((response) =>
      dispath(
        setBuyCreate({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const payPayment = (data) => {
  return (dispath) => {
    Pay24.payPayment(data).then((response) =>
      dispath(setPaymentData(response))
    );
  };
};

export const getCheck = (data) => {
  return (dispath) => {
    Pay24.getCheck(data).then((response) => dispath(setPaymentCheck(response)));
  };
};

export default Pay24Reducer;
