import { P2P } from "../../api"
let initialState = {
	answerCreatedDeals: {
		open: false,
		message: "",
		severity: "",
	},
	answerDeletedDeals: {
		open: false,
		message: "",
		severity: "",
	},
	listDels: [],
	listDelsLoading: false,
	dealById: [],
	dealByIdLoading: false,
	answerJoinDeal: {
		open: false,
		message: "",
		severity: "",
	},
	answerAcceptPaymentSent: {
		open: false,
		message: "",
		severity: "",
	},
	answerAcceptPaymentGet: {
		open: false,
		message: "",
		severity: "",
	},
	answerAcceptPayment: {
		open: false,
		message: "",
		severity: "",
	},
	answerAccept: {
		open: false,
		message: "",
		severity: "",
	},
}
const P2PReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ANSWER_P2P_CREATED": {
			return {
				...state,
				answerCreatedDeals: action.alert,
			}
		}
		case "ANSWER_P2P_DELETED": {
			return {
				...state,
				answerDeletedDeals: action.alert,
			}
		}
		case "SET_LIST_DEALS": {
			return {
				...state,
				listDels: action.data,
				listDelsLoading: true,
			}
		}
		case "SET_DEAL_ID": {
			return {
				...state,
				dealById: action.data,
				dealByIdLoading: true,
			}
		}
		case "SET_ACCEPT_PAYMENT_SENT": {
			return {
				...state,
				answerAcceptPaymentSent: action.alert,
				answerAcceptPayment: action.alert,
			}
		}
		case "SET_ACCEPT_PAYMENT_GET": {
			return {
				...state,
				answerAcceptPaymentGet: action.alert,
				answerAcceptPayment: action.alert,
			}
		}
		case "SET_ACCEPT": {
			return {
				...state,
				answerAccept: action.alert,
			}
		}
		case "SET_MY_DEALS": {
			return {
				...state,
				myDeals: action.data,
			}
		}
		case "SET_COMMISSION": {
			return {
				...state,
				commission: action.data,
			}
		}
		case "SET_MY_DEALS_ACTIVE": {
			return {
				...state,
				myDealsProcess: Array.isArray(action.data) && action.data.filter((item) => item.status === "process"),
			}
		}
		default:
			return state
	}
}
export const setCreatedDeals = (alert) => ({
	type: "ANSWER_P2P_CREATED",
	alert,
})
export const setDeleteDeal = (alert) => ({
	type: "ANSWER_P2P_DELETED",
	alert,
})
export const setListDeals = (data) => ({ type: "SET_LIST_DEALS", data })
export const setDealById = (data) => ({ type: "SET_DEAL_ID", data })

export const setAccept = (alert) => ({ type: "SET_ACCEPT", alert })

export const setAcceptPaymentSent = (alert) => ({
	type: "SET_ACCEPT_PAYMENT_SENT",
	alert,
})
export const setAcceptPaymentGet = (alert) => ({
	type: "SET_ACCEPT_PAYMENT_GET",
	alert,
})
export const setMyDeals = (data) => ({ type: "SET_MY_DEALS", data })
export const setCommission = (data) => ({ type: "SET_COMMISSION", data })
export const setMyDealsProcess = (data) => ({ type: "SET_MY_DEALS_ACTIVE", data })

export const createDeals = (data) => {
	return (dispath) => {
		P2P.createDeals(data).then((response) =>
			dispath(
				setCreatedDeals({
					open: true,
					message: Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export const deleteDeal = (id) => {
	return (dispath) => {
		P2P.deleteDeal(id).then((response) =>
			dispath(
				setDeleteDeal({
					open: true,
					message: Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export const getCommission = () => {
	return (dispath) => {
		P2P.commission().then((response) => dispath(setCommission(response)))
	}
}

export const getListDeals = () => {
	return (dispath) => {
		P2P.listDeals().then((response) => dispath(setListDeals(response)))
	}
}

export const getMyDeals = () => {
	return (dispath) => {
		P2P.myDeals().then((response) => dispath(setMyDeals(response)))
	}
}

export const getDealById = (id) => {
	return (dispath) => {
		P2P.dealById(id).then((response) => dispath(setDealById(response)))
	}
}

export const getMyDealsProccess = () => {
	return (dispath) => {
		P2P.myDeals().then((response) => dispath(setMyDealsProcess(response)))
	}
}

export const joinToDeal = (id, data) => {
	return (dispath) => {
		P2P.joinToDeal(id, data).then((response) =>
			dispath(
				setAccept({
					open: true,
					message: response.status === 202 ? "Успешно присоединено" : Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export const AcceptPaymentSent = (id, data, type) => {
	return (dispath) => {
		P2P.acceptPaymentSent(id, data, type).then((response) =>
			dispath(
				setAcceptPaymentSent({
					open: true,
					message: Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export const AcceptPaymentGet = (id, data, type) => {
	return (dispath) => {
		P2P.acceptPaymentGet(id, data, type).then((response) =>
			dispath(
				setAcceptPaymentGet({
					open: true,
					message: Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export const AcceptCash = (id, acceptType) => {
	return (dispath) => {
		P2P.acceptCash(id, acceptType).then((response) =>
			dispath(
				setAccept({
					open: true,
					message: response.status === 202 ? "Торговля была успешно завершена" : Object.values(response.data)[0],
					severity: response.status,
				}),
			),
		)
	}
}

export default P2PReducer
