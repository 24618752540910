import { ActivationAccount } from "../../api";
let initialState = {
  answerActivationAccountAlert: {
    open: false,
    message: "",
    severity: "",
  },
  resended_code_data: {
    open: false,
    message: "",
    severity: "",
  },
};
const ActivationAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ANSWER_ACTIVATION_ACCOUNT": {
      return {
        ...state,
        answerActivationAccountAlert: action.alert,
      };
    }

    case "RESEND_CODE": {
      return {
        ...state,
        resended_code_data: action.alert,
      };
    }

    default:
      return state;
  }
};
export const setAnswerActivationAccount = (alert) => ({
  type: "ANSWER_ACTIVATION_ACCOUNT",
  alert,
});

export const setResendedCodeData = (alert) => ({
  type: "RESEND_CODE",
  alert,
});

export const postActivationAccountData = (ActivationAccountData) => {
  return (dispath) => {
    ActivationAccount.postActivationAccount(ActivationAccountData).then(
      (response) =>
        dispath(
          setAnswerActivationAccount({
            open: true,
            message: response.data,
            severity: response.status,
            data: response,
          })
        )
    );
  };
};

export const resendActivationAccountCode = (data) => {
  return (dispath) => {
    ActivationAccount.resendActivationAccountCode(data).then((response) =>
      dispath(
        setResendedCodeData({
          open: true,
          message: response.data,
          severity: response.status,
          data: response,
        })
      )
    );
  };
};

export default ActivationAccountReducer;
