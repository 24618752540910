import { Reviews } from "../../api/";

const initState = {
  reviews: [],
  addedReview: {
    open: false,
    message: "",
    severity: "",
  },
};

const reviewsReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_REVIEWS": {
      return {
        ...state,
        reviews: action.data,
      };
    }

    case "POST_REVIEW": {
      return {
        ...state,
        addedReview: action.data,
      };
    }

    default:
      return state;
  }
};

export const setReviews = (data) => ({
  type: "GET_REVIEWS",
  data,
});

export const setAddedReview = (data) => ({
  type: "POST_REVIEW",
  data,
});

export const getReviews = () => {
  return (dispath) => {
    Reviews.getReviews().then((response) => dispath(setReviews(response)));
  };
};

export const postReview = (data) => {
  return (dispath) => {
    Reviews.postReview(data).then((response) =>
      dispath(
        setAddedReview({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export default reviewsReducer;
