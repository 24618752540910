import cookie from "cookie_js";

export const isAuthenticated = () => {
  const token = cookie.get("token_netex");
  try {
    if (token) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const useAuth = () => {
  const user = cookie.get("token_netex");
  return !!user;
};
