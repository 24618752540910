import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";
import store from "./redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Reload from "./../src/components/Reload";
import "./i18nextInit";

ReactDOM.render(
  <Suspense fallback={<Reload />}>
      <BrowserRouter basename={`/${localStorage.getItem("i18nextLng")}`}>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
  </Suspense>,
  document.getElementById("root")
);
