import { Sell } from "../../api";

let initialState = {
  setSellByParameterCoin: [],
  answerCreateSell: {
    open: false,
    message: "",
    severity: "",
  },
  answerSellConfirm: {
    open: false,
    message: "",
    severity: "",
  },
};

const SellReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELL_PARAMETER": {
      return {
        ...state,
        setSellByParameterCoin: action.data,
      };
    }
    case "SET_SELL_CREATE": {
      return {
        ...state,
        answerCreateSell: action.alert,
      };
    }
    case "SET_SELL_CONFIRM": {
      return {
        ...state,
        answerSellConfirm: action.data,
      };
    }
    default:
      return state;
  }
};
export const setSellByParameter = (data) => ({
  type: "SET_SELL_PARAMETER",
  data,
});
export const setSellCreate = (alert) => ({ type: "SET_SELL_CREATE", alert });
export const setSellConfirm = (data) => ({ type: "SET_SELL_CONFIRM", data });

export const getSellByParametersCoin = (parameter) => {
  return (dispath) => {
    Sell.getSellByParameters(parameter).then((response) =>
      dispath(setSellByParameter(response))
    );
  };
};

export const CreateSell = (data) => {
  return (dispath) => {
    Sell.makeSell(data).then((response) =>
      dispath(
        setSellCreate({
          open: response.status === 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export const getSellConfirm = (id) => {
  return (dispath) => {
    Sell.confirmSell(id).then((response) =>
      dispath(
        setSellConfirm({
          open: true,
          message: response.data,
          severity: response.status,
        })
      )
    );
  };
};

export default SellReducer;
