import { Withdraw } from "../../api";

let initialState = {
  withDrawParameters: [],
  withDrawData: [],
  withDrawDataConfirm: {
    open: false,
    message: "",
    severity: "",
  },
  alertOpen: true,
  isLoading: true,
};

const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WITHDRAW_PARAMETERS": {
      return {
        ...state,
        withDrawParameters: action?.data,
        networks: Object.values(
          action?.data?.data?.parameters.networks
        ).reverse(),
        isLoading: false,
      };
    }

    case "GET_WITHDRAW_DATA": {
      return {
        ...state,
        withDrawData: action.data,
        isLoading: false,
      };
    }

    case "GET_WITHDRAW_DATA_CONFIRM": {
      return {
        ...state,
        withDrawDataConfirm: action.data,
      };
    }

    case "SET_LOADING": {
      return {
        ...state,
        isLoading: action.data,
      };
    }

    default:
      return state;
  }
};

export const setLoading = (data) => ({
  type: "SET_LOADING",
  data,
});

export const setWithDrawParameters = (data) => ({
  type: "GET_WITHDRAW_PARAMETERS",
  data,
});

export const setWithDrawData = (data) => ({
  type: "GET_WITHDRAW_DATA",
  data,
});

export const setWithDrawDataConfirm = (data) => ({
  type: "GET_WITHDRAW_DATA_CONFIRM",
  data,
});

export const getWithDrawParameters = (coin) => {
  return (dispatch) => {
    Withdraw.getWithDrawParameters(coin).then((response) =>
      dispatch(setWithDrawParameters(response))
    );
  };
};

export const createWithDraw = (data) => {
  return (dispatch) => {
    Withdraw.createWithDraw(data).then((response) => {
      dispatch(
        setWithDrawData({
          open: response.status !== 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      );
    });
  };
};

export const confirmWithDraw = (id, data) => {
  return (dispatch) => {
    Withdraw.confirmWithDraw(id, data).then((response) => {
      dispatch(
        setWithDrawDataConfirm({
          open: response.status !== 200 ? true : false,
          message: response.data,
          severity: response.status,
        })
      );
    });
  };
};

export default withdrawReducer;
