import { Question } from "../../api";

let initialState = {
  faq: [],
  questions: {},
  answers: {},
  results: {},
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FAQ": {
      return {
        ...state,
        faq: action.data,
      };
    }

    case "SET_QESTIONS": {
      return {
        ...state,
        questions: action.data,
      };
    }

    case "SET_ANSWERS": {
      return {
        ...state,
        answers: action.data,
      };
    }

    case "SET_RESULTS": {
      return {
        ...state,
        results: action.data,
      };
    }

    default:
      return state;
  }
};

export const setFaq = (data) => ({ type: "GET_FAQ", data });
export const setQestions = (data) => ({ type: "SET_QESTIONS", data });
export const setAnswers = (data) => ({ type: "SET_ANSWERS", data });
export const setResults = (data) => ({ type: "SET_RESULTS", data });

export const getFaq = () => (dispatch) =>
  Question.getFaq().then((response) => dispatch(setFaq(response)));

export const getQuestions = () => (dispatch) =>
  Question.getQuestions().then((response) => dispatch(setQestions(response)));

export const getResults = () => (dispatch) =>
  Question.getResults().then((response) => dispatch(setResults(response)));

export const sendAnswers = (data) => (dispatch) =>
  Question.sendAnswers(data).then((response) => dispatch(setAnswers(response)));

export default questionReducer;
